import React, { useEffect, useState } from "react";
import "./HowItWorks.css";

function HowItWorks() {
  return (
    <div className='HowItWorks'>
      <div className='Leftcont'>
        <h3 className='headline'>Move Money Faster</h3>
        <p className='p1'>
          Send and receive funds wherever opportunity strikes, without limits or
          slowdowns. Our service ensures that your transactions are always fast
          and reliable.
        </p>
        <div className='store'>
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/nl/app/aibanc/id6450451179?l=en-GB",
                "_blank"
              )
            }
            src='/Images/apple-store-rounded-blue.svg'
            alt='apple'
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP",
                "_blank"
              )
            }
            src='/Images/playstore-rounded-blue.svg'
            alt='playstore'
          />
        </div>
      </div>
      <div className='HowItWorksrightcont'>
        <img
          src='/Images/move-money-faster.svg'
          width='543px'
          height='534px'
          alt=''
        />
      </div>
    </div>
  );
}

export default HowItWorks;

export function HowItWorks2() {
  return (
    <div className='HowItWorks2'>
      <div className='HowItWorksrightcont'>
        <img
          className='build-wealth'
          src='/Images/reward-3.svg'
          width='550px'
          height='534'
          alt=''
        />
      </div>
      <div className='Leftcont'>
        <h3 className='headline2'>Build Wealth Your Way</h3>
        <p className='p2'>
          Grow savings securely with competitive returns, or access flexible
          credit for opportunities you spot.
        </p>
        <div className='store0'>
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/nl/app/aibanc/id6450451179?l=en-GB",
                "_blank"
              )
            }
            src='/Images/apple-store-rounded-white.svg'
            alt='apple'
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP",
                "_blank"
              )
            }
            src='/Images/playstore-rounded-white.svg'
            alt='playstore'
          />
        </div>
      </div>
    </div>
  );
}

export function HowItWorks3() {
  return (
    <div className='HowItWorks3'>
      <div className='Leftcont'>
        <h3 className='headline3'>Rewards for Achieving More </h3>
        <p className='p3'>
          As you pursue your financial goals, receive exclusive perks designed
          just for you.
        </p>
        <div className='store1'>
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/nl/app/aibanc/id6450451179?l=en-GB",
                "_blank"
              )
            }
            src='/Images/apple-update.svg'
            alt='apple'
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP",
                "_blank"
              )
            }
            src='/Images/playstore-rounded-blue.svg'
            alt='playstore'
          />
        </div>
      </div>
      <div className='HowItWorksrightcont'>
        <img src='/Images/couples.svg' width='620px' height='534' alt='' />
      </div>
    </div>
  );
}

export function HowItWorks4() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = width <= 480; // Adjust breakpoint as needed

  return (
    <div className='HowItWorks4'>
      <div className='Leftcont'>
        <h3 className='headline14'>Support your love ones from afar</h3>
        <p className='p4'>
          You can rely on fast, zero-cost international transfers seamlessly
          within the app. Experience a new standard in international
          money transfers.
        </p>
        <div className='store20'>
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/nl/app/aibanc/id6450451179?l=en-GB",
                "_blank"
              )
            }
            src={
              isMobile ? "/Images/apple-w-w.svg" : "/Images/apple-update.svg"
            }
            alt='apple'
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP",
                "_blank"
              )
            }
            src={
              isMobile
                ? "/Images/android-w-w.svg"
                : "/Images/playstore-rounded-blue.svg"
            }
            alt='playstore'
          />
        </div>
      </div>
      <div className='HowItWorksrightcont2'>
        {/* <img
          src='/Images/move-money-faster.svg'
          width='620px'
          height='534'
          alt=''
        /> */}
      </div>
    </div>
  );
}
