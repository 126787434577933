import React from "react";
import "./LandingPageContent.css";

const landing2 = [
  "/Images/free-transaction.svg",
  "/Images/unlimited-access-update.svg",
  "/Images/new-card-info.svg",
];

function LandingPageContent() {
  return (
    <div className='landingPage'>
      <h2>Subscribe and Enjoy a World of Benefits</h2>
      <div className='landingPage-Flex'>
        {landing2.map((land, index) => (
          <div key={index}>
            <img className='landing-Image' src={land} alt={index} />
          </div>
        ))}
      </div>
      {/* <section className="CreditSection one">
				<div className="Creditleftcont">
					<div className="mockupleft">
						<img src="/Images/one.png" alt="" />
					</div>
				</div>
				<div className="Creditrightcont">
					<p className="smallHeadline">Instant Withdrawal</p>
					<div className="semiboldtext">
						Get the credit you need <br className="brr" /> within few minutes
					</div>
					<p className="semimediumtext">
						Initiate a withdrawal request and get credited directly into your
						bank account within minutes.
					</p>
					<div className="downloadNow">
						<a href="https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP">
							Download Now
						</a>
					</div>
				</div>
			</section>

			<section className="CreditSection two">
				<div className="Creditrightcont">
					<p className="smallHeadline">Affordable interest rate</p>
					<div className="semiboldtext">Enjoy low interest rate</div>
					<p className="semimediumtext">
						We offer an affordable interest rate, so you can get up to 6 months
						credit for as low as 3% monthly interest. Signup for credit today
						and get the credit you deserve.
					</p>
					<div className="downloadNow">
						<a href="https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP">
							Download Now
						</a>
					</div>
				</div>

				<div className="Creditleftcont">
					<div className="mockupleft">
						<img src="/Images/two.png" alt="" />
					</div>
				</div>
			</section>

			<section className="CreditSection three">
				<div className="Creditleftcont">
					<div className="mockupleft">
						<img src="/Images/three.png" alt="" />
					</div>
				</div>

				<div className="Creditrightcont">
					<p className="smallHeadline">Enjoy money rewards</p>
					<div className="semiboldtext">Get Cashback on your Credit</div>
					<p className="semimediumtext">
						Aibanc cashback reward service provides up to 15% cashback rewards
						when you repay your credit on or before the due date.
					</p>
					<div className="downloadNow">
						<a href="https://play.google.com/store/apps/details?id=co.aibanc.Aibanc_APP">
							Download Now
						</a>
					</div>
				</div>
			</section> */}
    </div>
  );
}

export default LandingPageContent;
