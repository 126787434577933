import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./privacy.css";
function Privacy() {
	return (
		<>
			<Navbar />
			<section className="p-container">
				<h1 className="text-center">PRIVACY POLICY</h1>
				<div>
					<h2>INTRODUCTION</h2>
					<p className="pp">
						<b>
							AIBANC TECHNOLOGIES LIMITED. (“AIBANC” or “we”, “us”, or “our”)
						</b>
						, is committed to protecting privacy and are committed to protecting
						your Personal Information (that may be collected through your use of
						our website or mobile application (together, Site) or in applying
						for or receiving any of our products or services (Services).
					</p>
					<p className="pp">
						This Policy describes how we collect, use, store, protect and
						disclose Personal Information we obtain from you when you visit the
						websites that we own or control (today or in the future), including
						<a href="http://www.aibanc.co"> www.aibanc.co </a>
						(collectively, the “Sites”), you use the mobile applications we own
						or control (today or in the future) (collectively, the
						“Applications” or “Apps”), and when you use any services we provide
						through the Sites or the Apps (collectively, with the Sites and the
						Apps, the “Services”).
					</p>
					<p className="pp">
						You can access the Sites in many ways, including from a computer or
						mobile phone, and this Policy will apply regardless of the means of
						access. This Policy also governs the use of Personal Information we
						obtain from you, from any third-party site or application that you
						authorize to provide to us Personal Information. Please be aware
						that AIBANC cannot control the privacy policies or practices of
						sites or companies that we do not own or control and cannot control
						the actions of people we do not employ or manage.
					</p>
					<p className="pp">
						Please read this Policy carefully. This Policy is a legally binding
						contract between us and anyone who accesses or uses any of our
						Services.
					</p>
				</div>
				<div>
					<h4 className="pp">A. INFORMATION COLLECTED</h4>
					<p className="pp">
						<b>AIBANC</b> collects personal data such as name, home address,
						telephone, email address, date of birth, username, password and
						other registration information, identification card number,
						financial details, employment details and any other information
						necessary to fully access our services, which is voluntarily
						disclosed during your interaction with us.
					</p>
					<p className="pp">
						Generally, we collect personal information directly from you. In
						most circumstances where the personal information that we collect
						about you is held by a third party, we will obtain your permission
						before we seek out this information from such sources.
					</p>
					<h4 className="pp">
						<i>1. INFORMATION AUTOMATICALLY COLLECTED</i>
					</h4>
					<p className="pp">
						When you use our Services, our servers automatically record
						information that your browser or device sends whenever you visit a
						website or utilize an application. This information may include your
						IP address, the type of device you are using, the internet service
						provider or mobile carrier you are using, your device identifiers,
						your mobile telephone number, your geographic location and your
						activities within the Services, including the links you click, the
						pages or screens you view, your session time, the number of times
						you click a page/screen or use a feature of the Services, the date
						and time you click on a page or use a feature and the amount of time
						you spend on a page or using a feature.
					</p>
					<p className="pp">
						Like many websites, our Sites also use “cookie” technology to
						collect additional website usage data and to improve the Sites and
						our Services. A cookie is a small data file that we transfer to your
						computer’s hard disk. A session cookie enables certain features of
						the Sites and is deleted from your computer when you disconnect from
						or leave the Sites. A persistent cookie remains after you close your
						browser and may be used by your browser on subsequent visits to our
						Sites. We may use both session and persistent cookies to better
						understand how you interact with our Sites, to monitor aggregate
						usage by our uses and web traffic routing on our Sites, and to
						improve our Sites and Services. You may also be able to configure
						your computer or mobile device to limit the collection of these
						“cookies,” but that limitation may also limit our ability to provide
						all the services or functionalities of the Sites.
					</p>
					<h4 className="pp">
						<i>2. INFORMATION COLLECTED FROM THIRD PARTIES</i>
					</h4>
					<p className="pp">
						We may display third-party content on the Sites, including
						third-party advertising. Third-party content may use cookies, web
						beacons or other mechanisms for obtaining data in connection with
						your interaction with such third-party content on our Sites.
						Information collected through third-party web beacons and buttons is
						collected directly by these third parties, not by AIBANC.
					</p>
					<p className="pp">
						Sites may contain links to other websites. The fact that we link to
						a website is not an endorsement, authorization, or representation of
						our affiliation with that third party. We do not exercise control
						over third party websites. These other websites may place their own
						cookies or other files on your computer or mobile device, collect
						data or solicit Personal Information from you. Other sites follow
						different rules regarding the use or disclosure of the Personal
						Information you submit to them.
					</p>
					<h4 className="pp">B. HOW WE USE THE INFORMATION WE COLLECT?</h4>
					<p className="pp">
						We may use the information we collect for non-marketing purposes
						including: (1) validating your identity; (2) sending you push
						notifications or e-mails to provide you with alerts and updates
						about your account and our Services; (3) processing your
						transactions (to determine your eligibility for any of our product
						or services); (4) conducting statistical or demographic analysis;
						(5) complying with legal and regulatory requirements; (6)
						customizing your experience with the Services; (7) protecting and
						defending AIBANC and its affiliates against legal actions or claims;
						(8) preventing fraud; (9) debt collection; (10) satisfying
						contractual obligations; (11) cooperating with law enforcement or
						other government agencies for purposes of investigations, national
						security, public safety or matters of public importance when we
						believe that disclosure of Personal Information is necessary or
						appropriate to protect the public interest; (12) improving customer
						service; (13) providing you the features and functionalities
						associated with the Services; (14) any other purposes for which we
						obtain your consent; and (15) for other business purposes permitted
						under applicable law (collectively, “Non-Marketing Purposes”).
					</p>
					<p className="pp">
						With your advance consent, we may use the Personal Information we
						collect for our own marketing purposes including notifying you of
						special promotions, offers and events via push notifications, email
						and other means, including SMS messaging, subject to compliance with
						applicable laws. We may also link Personal Information (including
						your name, mobile phone number, and email address) with non-personal
						information (including Personal Information automatically collected
						as described in this Policy) and use such information for our own
						marketing purposes.
					</p>
					<p className="pp">
						We may also use information in the aggregate to understand how our
						users as a group use the Services provided on our applications. We
						will not collect additional categories of Personal Information or
						use the Personal Information we collect for materially different,
						unrelated or incompatible purposes without providing you notice.
					</p>
					<h4 className="pp">C. WHO WE SHARE THE INFORMATION WITH?</h4>
					<p className="pp">
						For business purposes, we may share certain Personal Information we
						receive from and about you, and about your transactions with us,
						with our affiliates and certain third parties. Doing so allows us to
						provide the Services you request.
					</p>
					<p className="pp">
						AIBANC shall secure the confidentiality of your data and personal
						information to avoid unauthorized without prejudice to our right to
						share information, including Personal Information, with third
						parties, as described below:
						<ul className="list">
							<li>
								Affiliates: We may share information with a parent company,
								subsidiaries, joint ventures, or other companies under common
								control with us who shall also comply with the terms of this
								Privacy Policy. We provide such information to other trusted
								businesses or persons for the purpose of processing personal
								information on our behalf. We require that these parties agree
								to process such information based on our instructions and in
								compliance with this Privacy Policy and any other appropriate
								confidentiality and security measures.
							</li>
							<li>
								Service Providers: We may share information with service
								providers that help us perform functions and process your
								transactions.
							</li>
							<li>
								Corporate Structure: We may share information in connection with
								a merger, acquisition, consolidation, change of control, or sale
								of all or a portion of our assets or if we undergo bankruptcy or
								liquidation.
							</li>
							<li>
								Other Companies: We may share information with other companies
								for purposes of marketing our products to you or for analysing
								our business and transactions.
							</li>
							<li>
								As Required by Law: We will share information where we are
								legally required to do so, such as in response to court orders
								or legal process, to establish, protect, or exercise our legal
								rights, to defend against legal claims or demands, or to comply
								with the requirements of mandatory applicable law.
							</li>
							<li>
								To Prevent Harm: We may share information if we believe it is
								necessary to detect, investigate, prevent, or act against
								illegal activities, fraud, or situations involving potential
								threats to the rights, property, or personal safety of any
								person.
							</li>
							<li>
								Reasonable Necessity: We have a good faith belief that access,
								use, preservation or disclosure of such information is
								reasonably necessary to:
							</li>
							<ul className="sub-list">
								<li>
									satisfy any applicable law, regulation, legal process, or
									enforceable governmental request.
								</li>
								<li>
									enforce applicable terms of service, including investigation
									of potential violations thereof, necessary to enforce
									repayment on loans.
								</li>
								<li>
									detect, prevent, or otherwise address fraud, security, or
									technical issues; or
								</li>
								<li>
									protect against imminent harm to the rights, property, or
									safety of AIBANC, its users or the public as required or
									permitted by law.
								</li>
							</ul>
							<li>
								Without Identifying You: We may share information in a manner
								that does not identify you. For example, we may share anonymous,
								aggregate information about your use of our Site and products
								with our service providers.
							</li>
							<li>
								To enforce our Terms and Conditions and other agreements or to
								investigate potential breaches; report defaulters to any credit
								bureau in accordance with the Terms of Use of our services; or
								for the purpose of publishing statistics relating to the use of
								the App, in which case all information may be aggregated or made
								anonymous.
							</li>
							<li>
								With your Consent: We may request your permission to share your
								personal information for a specific purpose. We will notify you
								and request consent before you provide personal information or
								before the personal information you have already provided is
								shared for such purposes.
							</li>
						</ul>
					</p>
					<h4 className="pp">D. MANAGING YOUR PERSONAL INFORMATION</h4>
					<p className="pp">
						You may decline to share certain information with us, in which case,
						we may not be able to provide you some of the Services we offer. To
						protect your privacy and security, we reserve the right to take
						reasonable steps to verify your identity before granting you account
						access or making corrections to your information. You are
						responsible for always maintaining the secrecy of your unique
						password and account information.
					</p>
					<p className="pp">
						With your advance consent, you may receive mailings or emails from
						us with news or other information on events, products, services,
						discounts, special promotions, upcoming events, or other offers from
						us or on our behalf. If at any time you wish to stop receiving
						emails or mailings from us, please send us an email at
						<a href="mailto:info@aibanc.co"> info@AIBANC.co </a> and we will
						remove you from our mailing list.
					</p>
					<p>
						Alternatively, for email communications, you may opt out of
						receiving such communications by following the unsubscribe
						instructions set forth in email messages from us. Your unsubscribe
						request or email preferences change will be processed promptly,
						though this process may take several days. During that processing
						period, you may receive additional emails from us.
					</p>
					<h4 className="pp">E. SECURITY</h4>
					<p className="pp">
						AIBANC is committed to protecting the security of the Personal
						Information you provide to us and that we collect about you. We
						maintain commercially reasonable safeguards to maintain the security
						and privacy of Personal Information that you provide to us. Our
						safeguards vary based on the sensitivity of the information that we
						collect and store. Additionally, our Services are designed to comply
						with the laws applicable to the highly regulated financial services
						industry. This includes utilizing practices and measures that are
						designed to comply with the requirements for safeguarding, using,
						and protecting personal information as set out in laws such as EU’s
						General Data Protection Regulation and the Nigeria Data Protection
						Regulation.
					</p>
					<p className="pp">
						Nevertheless, when disclosing Personal Information, you should
						remain mindful that there is an inherent risk in the use of email
						and the internet. Your information may be intercepted without our
						consent, collected illegally, and used by others without your
						consent. We cannot guarantee the security of any information you
						disclose online, and you do so at your own risk. We recommend that
						you change your password periodically. You are responsible for
						maintaining the security of your account username and password. If
						you believe that your account username and/or password have been
						stolen or been made known to others, it is your responsibility to
						immediately contact us and change your password. We are not
						responsible if someone else accesses your account through
						information they have obtained from you.
					</p>
					<h4 className="pp">F. DATA STORAGE</h4>
					<p className="pp">
						All Personal Information stored by AIBANC with respect to your
						account will be stored, and may be shared or used, by AIBANC in
						accordance with this Policy. By submitting your personal data, you
						agree to this transfer, storing or processing. We will take all
						steps reasonably necessary to ensure that your data is treated
						securely and in accordance with this privacy policy.
					</p>
					<p className="pp">
						We erase/delete personal data in the event of any of the following:
						<ol type="i">
							<li>
								The personal data is no longer necessary in relation to the
								purposes for which they were collected or processed;
							</li>
							<li>
								You withdraw your consent or object to the processing and there
								is no overriding lawful basis for the processing;
							</li>
							<li>
								The personal data was unlawfully collected or processed in the
								first place;
							</li>
							<li>
								In compliance with Nigeria Information Technology Development
								Agency (NITDA)’s or any other lawful directive.
							</li>
						</ol>
					</p>
					<h4 className="pp">G. HOW LONG WE KEEP YOUR PERSONAL INFORMATION?</h4>
					<p className="pp">
						We will hold your personal information on AIBANC’s systems for as
						long as is necessary to fulfil the purpose for which it was
						collected or to comply with applicable legal, regulatory, or
						internal policy requirements.
					</p>
					<h4 className="pp">H. CHANGES TO THIS POLICY</h4>
					<p className="pp">
						We may revise this Policy from time to time in our sole discretion.
						We will notify you of any material revisions by placing notice of
						the revised Policy on our website or any place through which you
						access the Services. Your continued access or use of the Services
						following any amendments to the Policy constitutes your acceptance
						of the Policy as amended. It is your sole responsibility to monitor
						our website for changes to the Policy.
					</p>
					<h4 className="pp">I. CONTACT US:</h4>
					<p className="pp">
						If you would like to review, update, or correct your information or
						modify your communication preferences, or if you have questions
						regarding our Privacy Policy or the use of information collected,
						you can contact us at: <a href="mailto:support@aibanc.co"> support@aibanc.co</a>.
					</p>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Privacy;
