import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQs.css";

function FAQs() {
  return (
    <div className='faqsection'>
      <h3 className='faqheadline'>Frequently Asked Questions</h3>
      <div
        className='test'
        style={{
          margin: "auto",
          width: "90%",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
          padding: "59px, 48px, 59px ,48px",
          gap: "34px",
          borderRadius: "20px",
          background: "#F4F6FC",
        }}
      >
        <div className='accordions accordions-mobile'>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "29px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "5px 0px",
                }}
              >
                <p className='question'>What is Aibanc?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Aibanc is a financial platform offering essential money
                  services through simple subscription plans. Our goal is making
                  access inclusive, affordable and frictionless for you.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={2}
            style={{
              border: "0",
              borderBottomWidth: "0",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>How does subscription work?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Choose between flexible monthly or annual plans. Sign up and
                  start using core services like Bank account, Credit line,
                  Investment, Remittance, savings etc without any fees or
                  interest rate.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>What does the subscription include?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Check our plans page for full details, but subscribers get
                  core services like Bank account, Credit line, Investment,
                  Remittance, savings etc., as well as special perks.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>Is my money secure?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Security is our top priority. We use bank-grade encryption and
                  do not store sensitive financial info.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='accordions'>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>How do I sign up?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Download our app, sign up, verify identity and select the
                  right subscription for you. Enjoy affordable banking and more.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>Can I cancel my subscription?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Subscriptions are flexible. You can downgrade, upgrade or
                  cancel any time.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={2}>
            <AccordionSummary
              expandIcon={
                <img
                  src='/Images/plus-icon.svg'
                  width={"35px"}
                  height={"35px"}
                  alt='icon'
                />
              }
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  color: "#172B4D",
                  boxshadow: "none",
                  padding: "10px 0px",
                }}
              >
                <p className='question'>What if I have other questions?</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "23px",
                  color: "#5B5971",
                }}
              >
                <p className='answer'>
                  Please explore our Help Center for support articles or reach
                  out to our dedicated customer representatives anytime via live
                  chat or call. We're here to help!
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
